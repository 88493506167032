<template>
  <div class="youth-home-stats" v-if="hasPerm('reports.view_')">
    <page-header
      :title="'Déclaration CAF - ' + (detail ? 'Paramétrage personnalisé' : 'Par types de séances')"
      icon="fas fa-chart-bar"
      :links="getLinks()"
      print-hour
    >
    </page-header>
    <div class="youth-home-days">
      <b-row>
        <b-col cols="9">
          <div>
            <loading-gif :loading-name="statsLoading"></loading-gif>
            <b-row v-show="!isLoading(statsLoading) && showContent && !generateMode">
              <b-col cols="9">
                <date-range-selector @change="onDateRangeChanged" civil-first></date-range-selector>
              </b-col>
              <b-col cols="3">
                <div class="btn-holder">
                  <a class="btn btn-primary btn-block" @click.prevent="loadData()" :class="{ disabled: !isLoadStatsActive, }">
                    Rafraîchir
                  </a>
                </div>
              </b-col>
            </b-row>
            <b-row v-show="!isLoading(statsLoading) && showContent && !generateMode">
              <b-col>
                <div class="filter-bar">
                  <b-form-checkbox
                    id="onlyHandicapWithDoc"
                    v-model="onlyHandicapWithDoc"
                  >
                    Seulement les enfants handicapés avec attestation CAF ou CDAPH vérifiée
                  </b-form-checkbox>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <generate-youth-home-stats
                  v-show="!isLoading(statsLoading)"
                  :start-date="startDate"
                  :end-date="endDate"
                  @generateMode="generateMode = $event.mode"
                  @generating="disablePage"
                  @generated="reloadPage"
                >
                </generate-youth-home-stats>
              </b-col>
            </b-row>
          </div>
          <div v-if="!isLoading(statsLoading) && showContent && youthHomes.length" ref="printMe">
            <b>Données du {{ startDataDate | dateToString }} au {{ endDataDate | dateToString }}</b>
            <print-hour :print="printDone" label="imprimé le" :css-style="{ 'margin-left': '5px', display: 'inline-block'}">
            </print-hour>
            <div>
              <div class="stats" ref="excelMe">
                <div v-for="youthHome of filteredYouthHomes" :key="youthHome.id" class="youth-home">
                  <div class="header">
                    <b-row>
                      <b-col>{{ youthHome.name }}</b-col>
                      <b-col cols="1" class="text-right">
                        <span v-if="detail">
                          <a href @click.prevent="downloadDetail(youthHome)">
                            <i class="fa fa-file-excel"></i>
                          </a>
                        </span>
                      </b-col>
                    </b-row>
                  </div>
                  <b-row>
                    <b-col>
                      <div v-for="grouper of filteredGroupers" :key="grouper.id" class="seance-type">
                        <b-row v-if="hasSeances(grouper, youthHome)">
                          <b-col>
                            <b>{{ grouper.name }}</b>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <div v-for="place of filteredPlaces" :key="place.id">
                              <table
                                v-if="placeHasData(youthHome, grouper, place)"
                                class="places"
                              >
                                <tr v-show="false">
                                  <th colspan="17">
                                    {{ youthHome.name }}
                                    {{ grouper.name }}
                                  </th>
                                </tr>
                                <tr v-if="places.length > 1">
                                  <th colspan="17">Lieu: {{ place.name }}</th>
                                </tr>
                                <tr class="centered">
                                  <th></th>
                                  <th :colspan="showDetail ? 9 : 4">Tous</th>
                                  <th :colspan="showDetail ? 9 : 4" class="h-col">Enfants handicapés</th>
                                </tr>
                                <tr class="centered">
                                  <th></th>
                                  <th v-b-tooltip="'Durées CAF'" v-if="showDetail">
                                    <i class="fa fa-file"></i>
                                  </th>
                                  <th v-b-tooltip="'Durées Facturées'" v-if="showDetail">
                                    <i class="fa fa-euro"></i>
                                  </th>
                                  <th v-b-tooltip="'Durées Pointées'" v-if="showDetail">
                                    <i class="fa fa-clock-o"></i>
                                  </th>
                                  <th v-b-tooltip="'Durées Réalisées'" v-if="showDetail">
                                    <i class="fa fa-bar-chart-o"></i>
                                  </th>
                                  <th v-b-tooltip="'Durées à facturer aux familles'" v-if="showDetail">
                                    <i class="fa fa-users"></i>
                                  </th>
                                  <th class="caf-col" v-b-tooltip="'Heures facturées CAF'">
                                    <i class="fa fa-euro"></i> <span v-if="!showDetail">Facturé</span>
                                  </th>
                                  <th class="caf-col" v-b-tooltip="'Heures réalisées CAF'">
                                    <i class="fa fa-clock"></i> <span v-if="!showDetail">Réalisé</span>
                                  </th>
                                  <th class="caf-col" v-b-tooltip="'Heures ouvrant droit à PS'">
                                    <i class="fa fa-bar-chart-o"></i> <span v-if="!showDetail">PS</span>
                                  </th>
                                  <th v-b-tooltip="'Nombre d\'enfants accueillis'">
                                    <i class="fa fa-users"></i> <span v-if="!showDetail">Enfants</span>
                                  </th>
                                  <!-- -->
                                  <th class="h-col" v-b-tooltip="'Durées CAF'" v-if="showDetail">
                                    <i class="fa fa-file"></i>
                                  </th>
                                  <th class="h-col" v-b-tooltip="'Durées Facturées'" v-if="showDetail">
                                    <i class="fa fa-euro"></i>
                                  </th>
                                  <th class="h-col" v-b-tooltip="'Durées Pointées'" v-if="showDetail">
                                    <i class="fa fa-clock-o"></i>
                                  </th>
                                  <th class="h-col" v-b-tooltip="'Durées Réalisées'" v-if="showDetail">
                                    <i class="fa fa-bar-chart-o"></i>
                                  </th>
                                  <th class="h-col" v-b-tooltip="'Durées à facturer aux familles'" v-if="showDetail">
                                    <i class="fa fa-users"></i>
                                  </th>
                                  <th class="h-col caf-col" v-b-tooltip="'Heures facturées CAF'">
                                    <i class="fa fa-euro"></i> <span v-if="!showDetail">Facturé</span>
                                  </th>
                                  <th class="h-col caf-col" v-b-tooltip="'Heures réalisées CAF'">
                                    <i class="fa fa-clock"></i> <span v-if="!showDetail">Réalisé</span>
                                  </th>
                                  <th class="h-col caf-col" v-b-tooltip="'Heures ouvrant droit à PS'">
                                    <i class="fa fa-bar-chart-o"></i> <span v-if="!showDetail">PS</span>
                                  </th>
                                  <th class="h-col" v-b-tooltip="'Nombre d\'enfants accueillis'">
                                    <i class="fa fa-users"></i> <span v-if="!showDetail">Enfants</span>
                                  </th>
                                </tr>
                                <tr v-if="hasMissingGroupData(youthHome, grouper, place)"  class="age-groups">
                                  <th class="error">Hors groupe</th>
                                  <td
                                    v-for="elt of getData(youthHome, grouper, place, null, false)"
                                    :key="elt.key" class="number" :class="isCafCol(elt) ? 'caf-col' : ''"
                                  >
                                    {{ elt.value }}
                                  </td>
                                  <td
                                    v-for="elt of getData(youthHome, grouper, place, null, true)"
                                    :key="elt.key" class="number h-col" :class="isCafCol(elt) ? 'caf-col' : ''"
                                  >
                                    {{ elt.value }}
                                  </td>
                                </tr>
                                <tr
                                  v-for="ageGroup of getAgeGroups(youthHome)" :key="ageGroup.id"
                                  class="age-groups"
                                >
                                  <th>{{ ageGroup.name }}</th>
                                  <td
                                    v-for="elt of getData(youthHome, grouper, place, ageGroup, false)"
                                    :key="elt.key" class="number" :class="isCafCol(elt) ? 'caf-col' : ''"
                                  >
                                    {{ elt.value }}
                                  </td>
                                  <td
                                    v-for="elt of getData(youthHome, grouper, place, ageGroup, true)"
                                    :key="elt.key" class="number h-col" :class="isCafCol(elt) ? 'caf-col' : ''"
                                  >
                                    {{ elt.value }}
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </b-col>
                        </b-row>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </div>
          </div>
        </b-col>
        <b-col cols="3">
          <h3 class="separator-title">Filtres</h3>
          <b-form-group
            id="youth-homes-group"
            :label="youthHomeLabel"
            label-for="youthHomes"
          >
            <b-form-select v-model="selectedYouthHome" id="youthHomes" :disabled="disabled">
              <b-select-option v-for="elt of youthHomesFilters" :key="elt.id" :value="elt">
                {{ elt.name }}
              </b-select-option>
            </b-form-select>
          </b-form-group>
          <b-form-group
            id="seance-types-group"
            label="Type de séances"
            label-for="groupers"
          >
            <b-form-select v-model="selectedGrouper" id="groupers" :disabled="disabled">
              <b-select-option v-for="elt of groupersFilters" :key="elt.id" :value="elt">
                {{ elt.name }}
              </b-select-option>
            </b-form-select>
          </b-form-group>
          <b-form-group
            id="places-group"
            label="Lieux"
            label-for="places"
            v-if="places.length > 1"
          >
            <b-form-select v-model="selectedPlace" id="places" :disabled="disabled">
              <b-select-option v-for="elt of placesFilters" :key="elt.id" :value="elt">
                {{ elt.name }}
              </b-select-option>
            </b-form-select>
          </b-form-group>
          <b-form-group
            id="age-group-group"
            label="Groupe d'age"
            label-for="ageGroup"
          >
            <b-form-select v-model="selectedAgeGroup" id="ageGroup" :disabled="disabled">
              <b-select-option v-for="elt of ageGroupsFilters" :key="elt.id" :value="elt">
                {{ elt.name }}
              </b-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapMutations } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif.vue'
import PageHeader from '@/components/Layout/PageHeader.vue'
import GenerateYouthHomeStats from '@/components/Stats/GenerateYouthHomeStats.vue'
import DateRangeSelector from '@/components/DateRange/DateRangeSelector.vue'
import PrintHour from '@/components/Layout/PrintHour.vue'
import { BackendMixin } from '@/mixins/backend'
import { makeChoice } from '@/types/base'
import { makeCafReportLine, makeCafAgeGroup, makePlace } from '@/types/reports'
import { makeSeanceType, makeYouthHome } from '@/types/youth'
import { distinct } from '@/utils/arrays'
import { BackendApi, openDocument } from '@/utils/http'
import moment from 'moment'
import store from '@/store'

export default {
  name: 'youth-home-reports',
  mixins: [BackendMixin],
  components: {
    PrintHour,
    LoadingGif,
    PageHeader,
    GenerateYouthHomeStats,
    DateRangeSelector,
  },
  props: {
    detail: {
      type: Boolean,
      defaultValue: false,
    },
  },
  data() {
    return {
      statsLoading: 'youth-home-stats',
      total: [],
      handicap: [],
      youthHomes: [],
      groupers: [],
      places: [],
      ageGroups: [],
      years: [],
      selectedYouthHome: null,
      selectedGrouper: null,
      selectedPlace: null,
      selectedAgeGroup: null,
      disabled: true,
      showContent: true,
      startDate: null,
      endDate: null,
      startDataDate: null,
      endDataDate: null,
      generateMode: false,
      youthHomeTypes: {},
      showDetail: false,
      onlyHandicapWithDoc: false,
      printDone: false,
    }
  },
  computed: {
    youthHomeLabel() {
      return store.getters.youthHomeLabel
    },
    youthHomesFilters() {
      return [makeYouthHome()].concat(this.uniqueYouthHomes)
    },
    groupersFilters() {
      return [makeChoice()].concat(this.groupers)
    },
    placesFilters() {
      return [makePlace()].concat(this.places)
    },
    ageGroupsFilters() {
      return [makeCafAgeGroup()].concat(this.ageGroups)
    },
    uniqueYouthHomes() {
      return distinct(this.youthHomes, 'number')
    },
    filteredYouthHomes() {
      if (this.selectedYouthHome && this.selectedYouthHome.number) {
        return this.uniqueYouthHomes.filter(elt => elt.number === this.selectedYouthHome.number)
      } else {
        return this.uniqueYouthHomes
      }
    },
    filteredGroupers() {
      if (this.selectedGrouper && this.selectedGrouper.id) {
        return this.groupers.filter(elt => elt.id === this.selectedGrouper.id)
      } else {
        return this.groupers
      }
    },
    filteredPlaces() {
      if (this.selectedPlace && (this.selectedPlace.id)) {
        return this.places.filter(elt => elt.id === this.selectedPlace.id)
      } else {
        return this.places
      }
    },
    filteredAgeGroups() {
      if (this.selectedAgeGroup && this.selectedAgeGroup.id) {
        return this.ageGroups.filter(elt => elt.id === this.selectedAgeGroup.id)
      } else {
        return this.ageGroups
      }
    },
    isLoadStatsActive() {
      return this.startDate && this.endDate
    },
  },
  mounted() {
    this.onLoaded()
  },
  watch: {
    detail: function() {},
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async onLoaded() {
      if (!this.detail) {
        this.startLoading(this.statsLoading)
        await this.loadSeanceTypes()
        this.endLoading(this.statsLoading)
      }
    },
    async loadData() {
      this.disabled = true
      this.startLoading(this.loadingName)
      await this.loadYouthHomes()
      await this.loadStats(this.detail)
      this.endLoading(this.loadingName)
      this.disabled = false
    },
    async loadYouthHomes() {
      if (!this.isLoadStatsActive) {
        return
      }
      let url = '/api/youth/youth-homes/?start_date=' + this.startDate + '&end_date=' + this.endDate
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.youthHomes = resp.data.map(elt => makeYouthHome(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadSeanceTypes() {
      let url = '/api/youth/seance-types/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.groupers = resp.data.map(elt => makeSeanceType(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadStats(detail) {
      if (!this.isLoadStatsActive) {
        return
      }
      const isValid = (this.startDate && this.endDate)
      if (isValid) {
        this.startDataDate = this.startDate
        this.endDataDate = this.endDate
        const urlPath = detail ? 'youth-detail-stats' : 'youth-stats'
        let url = '/reports/api/' + urlPath + '/?start_date=' + this.startDate + '&end_date=' + this.endDate
        if (this.onlyHandicapWithDoc) {
          url += '&only_handicap_with_doc=1'
        }
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          this.total = resp.data.total.map(elt => makeCafReportLine(elt))
          this.handicap = resp.data.handicap.map(elt => makeCafReportLine(elt))
          this.places = resp.data.places.map(elt => makePlace(elt))
          this.ageGroups = resp.data.age_groups.map(elt => makeCafAgeGroup(elt))
          this.youthHomeTypes = resp.data['youth_home_types']
          if (this.detail) {
            this.groupers = resp.data.categories.map(elt => makeChoice(elt))
          }
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    placeHasData(youthHome, grouper, place) {
      const selectedLines = this.total.filter(
        (line) => {
          return (
            (line.youthHomeNumber === youthHome.number) &&
            (line.grouperId === grouper.id) &&
            (line.placeId === place.id)
          )
        }
      )
      return (selectedLines.length > 0)
    },
    hasMissingGroupData(youthHome, grouper, place) {
      const selectedLines = this.total.filter(
        (line) => {
          return (
            (line.youthHomeNumber === youthHome.number) &&
            (line.grouperId === grouper.id) &&
            (line.placeId === place.id) &&
            (line.ageGroupId === 0)
          )
        }
      )
      return (selectedLines.length > 0)
    },
    getData(youthHome, grouper, place, ageGroup, handicap) {
      const lines = handicap ? this.handicap : this.total
      const selectedLines = lines.filter(
        (line) => {
          return (
            (line.youthHomeNumber === youthHome.number) &&
            (line.grouperId === grouper.id) &&
            (line.placeId === place.id) &&
            (line.ageGroupId === (ageGroup ? ageGroup.id : 0))
          )
        }
      )
      if (selectedLines.length > 0) {
        const selectedLine = selectedLines[0]
        let rows = []
        if (this.showDetail) {
          rows = rows.concat(
            [
              { key: 'modelDurationSum' + (handicap ? '*' : ''), value: selectedLine.modelDurationSum, },
              { key: 'seanceDurationSum' + (handicap ? '*' : ''), value: selectedLine.seanceDurationSum, },
              { key: 'clockingDurationSum' + (handicap ? '*' : ''), value: selectedLine.clockingDurationSum, },
              { key: 'realDurationSum' + (handicap ? '*' : ''), value: selectedLine.realDurationSum, },
              { key: 'paidDurationSum' + (handicap ? '*' : ''), value: selectedLine.paidDurationSum, }
            ]
          )
        }
        rows = rows.concat(
          [
            { key: 'cafPaidDurationSum' + (handicap ? '*' : ''), value: selectedLine.cafPaidDurationSum, },
            { key: 'cafRealDurationSum' + (handicap ? '*' : ''), value: selectedLine.cafRealDurationSum, },
            { key: 'cafEligibleDurationSum' + (handicap ? '*' : ''), value: selectedLine.cafEligibleDurationSum, },
            { key: 'childrenCount' + (handicap ? '*' : ''), value: selectedLine.childrenCount, }
          ]
        )
        return rows
      } else {
        let rows = []
        if (this.showDetail) {
          rows = rows.concat(
            [
              { key: 'modelDurationSum' + (handicap ? '*' : ''), value: 0, },
              { key: 'seanceDurationSum' + (handicap ? '*' : ''), value: 0, },
              { key: 'clockingDurationSum' + (handicap ? '*' : ''), value: 0, },
              { key: 'realDurationSum' + (handicap ? '*' : ''), value: 0, },
              { key: 'paidDurationSum' + (handicap ? '*' : ''), value: 0, }
            ]
          )
        }
        rows = rows.concat(
          [
            { key: 'cafPaidDurationSum' + (handicap ? '*' : ''), value: 0, },
            { key: 'cafRealDurationSum' + (handicap ? '*' : ''), value: 0, },
            { key: 'cafEligibleDurationSum' + (handicap ? '*' : ''), value: 0, },
            { key: 'childrenCount' + (handicap ? '*' : ''), value: 0, }
          ]
        )
        return rows
      }
    },
    disablePage() {
      this.showContent = false
    },
    reloadPage() {
      this.showContent = true
      this.loadData()
    },
    hasSeances(grouper, youthHome) {
      if (this.detail) {
        return this.total.filter(
          (line) => {
            return (
              (line.youthHomeNumber === youthHome.number) &&
              (line.grouperId === grouper.id)
            )
          }
        ).reduce(
          (acc, line) => {
            acc += line.youthHomeNumber
            return acc
          },
          0
        )
      } else {
        return grouper.youthHomes.indexOf(youthHome.number) >= 0 || grouper.youthHomes.length === 0
      }
    },
    isCafCol(elt) {
      return (elt.key.indexOf('caf') === 0)
    },
    getAgeGroups(youthHome) {
      const youthHomeGroupType = this.youthHomeTypes[youthHome.number] || 0
      return this.filteredAgeGroups.filter(elt => elt.groupType === youthHomeGroupType)
    },
    getLinks() {
      return [
        {
          id: 1,
          label: 'Pdf',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: (this.isLoading(this.statsLoading)) ? 'btn-secondary disabled' : 'btn-secondary',
        },
        {
          id: 2,
          label: 'Excel',
          callback: this.excelMe,
          icon: 'fa fa-file-excel',
          cssClass: (this.isLoading(this.statsLoading)) ? 'btn-secondary disabled' : 'btn-secondary',
        }
      ]
    },
    printMe() {
      this.printDone = true
      const ref = this.$refs.printMe
      const that = this
      this.$nextTick(
        () => {
          const docUrl = '/documents/standard/<key>/pdf/'
          const docSlug = 'dossier-caf-' + moment().format('YYYY-MM-DD')
          const docContent = ref.innerHTML.toString()
          this.printDone = false
          try {
            openDocument(docUrl, docSlug, docContent, 'dossier_caf')
          } catch (err) {
            that.addError(that.getErrorText(err))
          }
        }
      )
    },
    async excelMe() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const docSlug = 'dossier-caf-' + moment().format('YYYY-MM-DD')
      const docContent = this.$refs.excelMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async downloadDetail(youthHome) {
      const docUrl = '/reports/export-inscription-archives/<key>/'
      const startDate = moment(this.startDate).format('YYYY-MM-DD')
      const endDate = moment(this.endDate).format('YYYY-MM-DD')
      const docSlug = '' + youthHome.id + '_' + startDate + '_' + endDate
      try {
        await openDocument(docUrl, docSlug)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    onDateRangeChanged(event) {
      this.startDate = event.startDate
      this.endDate = event.endDate
    },
  },
}
</script>

<style scoped lang="less">
.stats table {
  width: 100%;
}
.stats table tr td,
.stats table tr th {
  border: solid 1px #f0f0f0;
  padding: 4px;
}
.stats .youth-home .header {
  background: #cff2ff;
  color: #000;
  text-align: left;
}
.stats .youth-home {
  margin-bottom: 30px;
}

.stats .seance-type {
  background: #eadfff;
  color: #000;
  text-align: left;
  margin-bottom: 10px;
}

.stats table.places tr th {
  background: #feeae3;
  color: #000;
  text-align: left;
}
.stats table tr td {
  text-align: center;
}

.stats table tr.centered th {
  background: #e0e0e0;
  color: #000;
  text-align: center;
}
table.places tr.age-groups th {
  background: #e0e0e0;
  color: #000;
  text-align: center;
  width: 14%;
  font-size: 14px;
}
table.places tr.age-groups th.error {
  background: #ff8888;
}
table tr.age-groups td {
  width: 5%;
  font-size: 14px;
}
.separator {
  margin-top: 20px;
  border-top: solid 1px #f0f0f0;
  padding-top: 20px;
}
h3.separator-title {
  font-size: 14px;
  font-weight: bold;
  padding: 10px;
  background: #f0f0f0;
}
td.h-col {
  background: #baccff;
}
th.h-col {
  background: #a1b7ff !important;
}
th.caf-col {
  background: #2a2a2a !important;
  color: #fff !important;
}
td.caf-col {
  background: #2a2a2a;
  color: #fff;
}
th.caf-col.h-col {
  background: #14224d !important;
  color: #fff !important;
}
td.caf-col.h-col {
  background: #14224d;
  color: #fff;
}
.btn-holder {
  padding-top: 30px;
}
.filter-bar {
  padding: 5px;
  background: #eee;
  font-weight: bold;
}
</style>
